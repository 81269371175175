import { useEffect } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
// import { PortisConnector } from '@web3-react/portis-connector';
//import { FortmaticConnector } from './Fortmatic';
import { NetworkConnector } from './NetworkConnector';
//import { useActiveWeb3React } from '../hooks';
//import { ethers } from 'ethers';
//import { useWalletModalToggle } from '../../state/application/hooks';

// create an array of blocked Ethereum addresses
const blocklist: string[] = [
  '0x1234567890abcdef',
  '0x0987654321fedcba',
  '0x78b2a1EB3581E490742d6F312cbDaaCe9dC77d94'
];

// create a function to check if a given Ethereum address is in the blocklist
function checkAddress(address: string): boolean {
  return blocklist.includes(address);
}

function BlockAddress() {
  const { account } = useWeb3React();

  useEffect(() => {
    try {
      if (account && checkAddress(account)) {
        throw new Error("My error");
      }
    } catch (error) {
      console.error(error);
    }
  }, [account])  
}

export default BlockAddress;

const REACT_APP_NETWORK_URL = process.env.REACT_APP_NETWORK_URL;
// const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
// const PORTIS_ID = process.env.REACT_APP_PORTIS_ID]

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1');

if (typeof REACT_APP_NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`);
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: REACT_APP_NETWORK_URL },
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any));
}


export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 568],
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 5: REACT_APP_NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
});



// mainnet only
 //export const fortmatic = new FortmaticConnector({
   //apiKey: FORMATIC_KEY ?? '',
   //chainId: 1
 //})

// mainnet only
// export const portis = new PortisConnector({
//   dAppId: PORTIS_ID ?? '',
//   networks: [1]
// })

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: REACT_APP_NETWORK_URL,
  appName: 'Brfi',
  appLogoUrl: 'https://brfi.pages.dev/512x512_App_Icon.png',
});