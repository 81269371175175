const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json';
const AAVE_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://tokenlist.aave.eth.link';
const SYNTHETIX_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://synths.snx.eth.link';
const WRAPPED_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://wrapped.tokensoft.eth.link';
const TNS_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://list.tkn.eth.link';
const ROLL_LIST = 'https://app.tryroll.com/tokens.json';
const CMC_ALL_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://defi.cmc.eth.link';
const DHARMA_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://tokenlist.dharma.eth.link';
const MYCRYPTO_LIST = 'https://uniswap.mycryptoapi.com/';
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json';
const TESTNET_LIST = 'https://wispy-bird-88a7.uniswap.workers.dev/?url=http://testnet.tokenlist.eth.link';
const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json';
const BRFI_LIST = 'https://raw.githubusercontent.com/bruhcryptofi/lists/main/rinkeby.tokenlist.json';


// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  BRFI_LIST,
  COMPOUND_LIST,
  AAVE_LIST,
  SYNTHETIX_LIST,
  TNS_LIST,
  WRAPPED_LIST,
  TESTNET_LIST,
  ROLL_LIST,
  CMC_ALL_LIST,
  DHARMA_LIST,
  MYCRYPTO_LIST,
  OPTIMISM_LIST,
  GEMINI_LIST,
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [BRFI_LIST];
